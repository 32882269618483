import {useStaticQuery, graphql} from "gatsby";

export const useSiteContent = () => {
  const { siteContent } = useStaticQuery(graphql`
              query SiteContentData {
                  siteContent {
                      id
                      about_us {
                          description
                          banner_label
                      }
                      areas_esp {
                          elem1 {
                              title
                              description
                          }
                          elem2 {
                              title
                              description
                          }
                          elem3 {
                              title
                              description
                          }
                          elem4 {
                              title
                              description
                          }
                          elem5 {
                              title
                              description
                          }
                          elem6 {
                              title
                              description
                          }
                      }
                      contact {
                          lat
                          lng
                      }
                      cover {
                          cover_background
                          title
                          description
                      }
                      footer {
                          lista_telefonos
                          map_url
                          address
                      }
                      navbar {
                          social_links {
                              instagram
                              linkedin
                              twitter
                              fb
                          }
                      }
                      not_found {
                          text
                          _404_img
                          _404_img_responsive
                      }
                      we_are_part {
                          afisofom_desc
                          afiservicios_desc
                          mlp_desc
                      }
                  }
              }
    `
  );

  return siteContent;
}
